import { BrowserView, MobileOnlyView } from "react-device-detect";
import DesktopInquiry from "../components/DesktopInquiry"
import styled from "styled-components";
import InquirySection from "../components/InquirySection";
import { Helmet } from "react-helmet-async";

const Inquiry = () => {
  return (
  <div>
    <Helmet>
        <meta name="title" content="미리웰 비즈" />
          <meta
            name="description"
            content="전국의 모든 기업과 건강 검진 센터를 연결해주는 미리웰 비즈입니다."
          />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://miriwell.com/inquiry" />
          <meta property="og:title" content="미리웰 비즈" />
          <meta
            property="og:description"
            content="전국의 모든 기업과 건강 검진 센터를 연결해주는 미리웰 비즈입니다."
          />
          <meta
            property="og:image"
            content="/image/logoBiz.svg"
          />
    </Helmet>
    <BrowserView style={{overflow: "hidden"}}>
      <DesktopInquiry />
      <InquirySection />
    </BrowserView>
    <MobileOnlyView>
      <Mobile>PC에서 접속가능합니다.</Mobile>
    </MobileOnlyView>
  </div>
  )
}

const Mobile = styled.div`
  font-family: "NanumSquareRound";
  font-size: 25px;
  text-align: center;
  margin-top: 30px;
`
export default Inquiry;