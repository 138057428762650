import { BrowserView } from "react-device-detect";
import styled from "styled-components";

const Footer = () => {
  const serviceUrl = "https://rexsw.com/miriwell_policy/service.html";
  const privacyUrl = "https://rexsw.com/miriwell_policy/privacy.html";
  const rexswUrl = "http://rexsw.com/";

  return (
    <BrowserView>
      <Container>
        <Content>
          <h4>렉스소프트(주) 사업자 정보</h4>
          <p>
            <span>대표이사</span> 원성호
          </p>
          <p>
            <span>사업자등록번호</span> 494-81-01071
          </p>
          <p>
            <span>주소</span> 서울 관악구 관악로1 서울대학교 연구공원본관 222호,
            223호
          </p>
          <p>
            <span>이메일</span> info@rexsw.com
          </p>
          <CorInfo>
            <InfoText>
              <div
                className="first"
                onClick={() => {
                  window.open(serviceUrl);
                }}
              >
                이용약관
              </div>
              <div
                onClick={() => {
                  window.open(privacyUrl);
                }}
              >
                개인정보처리방침
              </div>
            </InfoText>
            <img
              alt="rexSoft"
              onClick={() => {
                window.open(rexswUrl);
              }}
              src="/image/rexSoft.svg"
            />
          </CorInfo>
        </Content>
      </Container>
    </BrowserView>
  );
};

const Container = styled.div`
  font-family: "Pretendard-Regular";
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
`;

const Content = styled.footer`
  width: 850px;
  height: 420px;
  font-size: 18px;

  h4 {
    font-weight: 600;
    color: #9399a4;
    margin: 70px 0 50px 0;
  }
  p {
    font-weight: 400;
  }
  span {
    color: #9399a4;
    margin-right: 5px;
  }
`;

const CorInfo = styled.div`
  display: flex;
  margin-top: 50px;
  justify-content: space-between;

  img {
    width: 160px;
    margin-top: -5px;
    cursor: pointer;
  }
`;

const InfoText = styled.div`
  display: flex;
  height: 25px;

  div {
    font-size: 18px;
    font-weight: 700;
    margin-right: 25px;
    cursor: pointer;
  }
  .first {
    padding-right: 25px;
    border-right: 3px solid black;
  }
`;

export default Footer;
