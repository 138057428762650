import styled from "styled-components";

const DesktopMain = () => {
  return (
    <Container>
      <MainContent>
        <div>
          <img alt="logo" src="/image/logo.svg" style={{ width: "180px" }} />
          <h1>
            아파서 걱정말고 <br />
            아프기 전에 <br />
            미리미리 관리하세요
          </h1>
          <DownLoad>
            <p>앱스토어 다운로드</p>
            <div>
              <a href="https://play.google.com/store/apps/details?id=com.rexsoft.miriwell&hl=ko">
                <StoreBox>
                  <img alt="google" src="/image/iconGoogle.svg" />
                </StoreBox>
              </a>
              <a href="https://apps.apple.com/kr/app/%EB%AF%B8%EB%A6%AC%EC%9B%B0/id1658463338">
                <StoreBox>
                  <img alt="appstore" src="/image/iconAppStore.svg" />
                </StoreBox>
              </a>
            </div>
          </DownLoad>
        </div>
        <MainImg src="/image/main.svg" />
      </MainContent>
    </Container>
  );
};

const Container = styled.div`
  width: 100vw;
  height: 500px;
  display: flex;
  justify-content: center;
  background-color: #28c985;
  // background: linear-gradient(to bottom, #28c985 90%, transparent);
  opacity: 0.82;
  padding-top: 3%;
`;

const MainContent = styled.div`
  display: flex;
  width: 850px;
  // background-color: aqua;

  h1 {
    width: 430px;
    color: white;
    font-family: "NanumSquareRound";
    font-weight: 800;
    font-size: 45px;
  }
`;

const DownLoad = styled.div`
  display: flex;
  flex-direction: column;

  p {
    font-family: "NanumSquareRound";
    font-weight: 800;
    font-size: 17px;
    color: white;
  }
  div {
    display: flex;
  }
`;

const StoreBox = styled.div`
  width: 180px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 40px;
  background-color: white;
  margin-right: 20px;
  cursor: pointer;

  img {
    width: 130px;
  }
`;

const MainImg = styled.img`
  width: 420px;
  margin-top: -5%;
`;

export default DesktopMain;
