import styled from "styled-components";
import DesktopMain from "./DesktopMain";
import Footer from "./Footer";

const Desktop = () => {
  const serviceUrl = "https://rexsw.com/miriwell_policy/service.html";
  const privacyUrl = "https://rexsw.com/miriwell_policy/privacy.html";
  const rexswUrl = "http://rexsw.com/";

  return (
    <Container>
      <DesktopMain />
      <Intro>
        <h1>미리미리 건강성적 관리하세요!</h1>
        <h3>지금부터 미리웰과 함께하면 앞으로 더 건강한 삶을 살 수 있어요</h3>
        <img src="/image/score.svg" />
      </Intro>
      <PreviewContainer>
        <PreviewRight>
          <img src="/image/preview_1.svg" />
          <PreviewInfoRight>
            <div>
              <img src="/image/iconCheck.svg" />
              <h2>동일 연령 대비 건강한가요?</h2>
            </div>

            <h4>
              건강검진 받은 결과만 연동하면 <br />
              <span>질병위험에 대한 통계정보</span>를 알려드려요
            </h4>
          </PreviewInfoRight>
        </PreviewRight>

        <PreviewLeft>
          <PreviewInfoLeft>
            <div>
              <img src="/image/iconCheck.svg" />
              <h2>나의 건강 성적은?</h2>
            </div>

            <h4>
              나의 <span>장기별 건강나이</span>와 <br />
              <span>기대수명</span>도 알아볼 수 있어요
            </h4>
          </PreviewInfoLeft>
          <img src="/image/preview_2.svg" />
        </PreviewLeft>
      </PreviewContainer>
      <PreviewRight>
        <img src="/image/preview_3.svg" />
        <PreviewInfoRight>
          <div>
            <img src="/image/iconCheck.svg" />
            <h2>매일 건강을 챙겨봐요!</h2>
          </div>

          <h4>
            건강검진, 진료및처방, 증상기록 정보를 <br />
            한번에 모아 <span>나의 인생 건강수첩</span>으로 활용하세요
          </h4>
        </PreviewInfoRight>
      </PreviewRight>
      <PreviewLeft marginLeft="90px">
        <PreviewInfoLeft>
          <div>
            <img src="/image/iconCheck.svg" />
            <h2>미리 건강을 지켜봐요!</h2>
          </div>

          <h4>
            기록하고 검진하고 관리하면 건강은 지키고 <br />
            질병은 미리 예방할 수 있습니다
          </h4>
        </PreviewInfoLeft>
        <img src="/image/preview_4.svg" />
      </PreviewLeft>

      <Data>
        <h2>데이터 정확도를 높일수록 건강해질 수 있어요!</h2>
        <h4>건강 데이터를 한눈에 관리하고 부족한 부분을 채워볼까요?</h4>
        <DataContainer>
          <DataIcon>
            <div>
              <img src="/image/iconData_1.svg" />
            </div>
            <span>기본 데이터</span>
          </DataIcon>
          <DataIcon>
            <div>
              <img src="/image/iconData_2.svg" />
            </div>
            <span>
              국가 검진/ <br /> 진료 데이터
            </span>
          </DataIcon>
          <DataIcon>
            <div>
              <img src="/image/iconData_3.svg" />
            </div>
            <span>
              사설 검진 <br /> 데이터
            </span>
          </DataIcon>
          <DataIcon>
            <div>
              <img src="/image/iconData_4.svg" />
            </div>
            <span>
              라이프로그 <br /> 데이터
            </span>
          </DataIcon>
        </DataContainer>
      </Data>
      {/* <Footer /> */}
      {/* <Footer>
          <h4>렉스소프트(주) 사업자 정보</h4>
          <p>
            <span>대표이사</span> 원성호
          </p>
          <p>
            <span>사업자등록번호</span> 494-81-01071
          </p>
          <p>
            <span>주소</span> 서울 관악구 관악로1 서울대학교 연구공원본관 222호, 223호
          </p>
          <p>
            <span>이메일</span> info@rexsw.com
          </p>
          <CorInfo>
            <InfoText>
                <div className="first" onClick={() => {window.open(serviceUrl)}}>이용약관</div>
                <div onClick={() => {window.open(privacyUrl)}}>개인정보처리방침</div>
            </InfoText>
              <img alt="rexSoft" onClick={() => {window.open(rexswUrl)}} src="/image/rexSoft.svg" />        
          </CorInfo>
        </Footer> */}
    </Container>
  );
};

const Container = styled.div`
  font-family: "Pretendard-Regular";
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
`;

const Intro = styled.div`
  text-align: center;
  padding-bottom: 50px;

  h1 {
    font-size: 56px;
    font-weight: 800;
    color: #28c985;
    margin: 0;
    padding-top: 100px;
  }
  h3 {
    font-size: 35px;
    font-weight: 500;
    margin-bottom: 70px;
  }
`;

const PreviewContainer = styled.div`
  margin-bottom: 150px;
`;

const PreviewRight = styled.div`
  width: 1000px;
  display: flex;
  justify-content: center;
  font-family: "Pretendard-Regular";
  //  background-color: aliceblue;

  img {
    width: 320px;
  }
  h2 {
    font-weight: 800;
    font-size: 50px;
    color: #28c985;
    margin-left: 20px;
  }
  h4 {
    font-weight: 500;
    font-size: 30px;
    line-height: 150%;
    margin: 0;

    span {
      font-weight: 800;
    }
  }
`;

const PreviewInfoRight = styled.div`
  margin-left: 50px;

  img {
    width: 50px;
  }
  div {
    display: flex;
  }
`;

const PreviewLeft = styled.div`
  width: 1000px;
  display: flex;
  justify-content: center;
  font-family: "Pretendard-Regular";
  // background-color: aliceblue;

  img {
    width: 320px;
    margin-left: ${(props) => (props.marginLeft ? props.marginLeft : "210px")};
    margin-top: -150px;
  }
  h2 {
    font-weight: 800;
    font-size: 50px;
    color: #28c985;
    margin-left: 20px;
    margin-top: 50px;
  }
  h4 {
    font-weight: 500;
    font-size: 30px;
    line-height: 150%;
    margin: 0;

    span {
      font-weight: 800;
    }
  }
`;

const PreviewInfoLeft = styled.div`
  img {
    width: 50px;
    margin: 0;
  }
  div {
    display: flex;
  }
`;

const Data = styled.div`
  width: 100vw;
  height: 655px;
  background-color: rgba(40, 201, 133, 10%);
  margin-top: 150px;
  padding-top: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    color: #28c985;
    font-size: 50px;
    font-weight: 800;
  }
  h4 {
    color: black;
    font-size: 30px;
    font-weight: 400;
    margin: 0;
  }
`;

const DataContainer = styled.div`
  width: 1000px;
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
`;

const DataIcon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  div {
    width: 200px;
    height: 200px;
    background-color: white;
    border-radius: 50%;
    margin-bottom: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  span {
    font-weight: 800;
    font-size: 25px;
    color: #5e7c70;
    text-align: center;
  }
  img {
    width: 100px;
  }
`;

// const Footer = styled.footer`
//   width: 1000px;
//   height: 420px;
//   font-size: 18px;

//   h4 {
//     font-weight: 600;
//     color: #9399a4;
//     margin: 70px 0 50px 0;
//   }
//   p {
//     font-weight: 400;
//   }
//   span {
//     color: #9399a4;
//     margin-right: 5px;
//   }
// `;

const CorInfo = styled.div`
  display: flex;
  margin-top: 50px;
  justify-content: space-between;

  img {
    width: 160px;
    margin-top: -5px;
    cursor: pointer;
  }
`;

const InfoText = styled.div`
  display: flex;
  height: 25px;

  div {
    font-size: 18px;
    font-weight: 700;
    margin-right: 25px;
    cursor: pointer;
  }
  .first {
    padding-right: 25px;
    border-right: 3px solid black;
  }
`;

export default Desktop;
