import { BrowserView, MobileOnlyView } from "react-device-detect";
import Desktop from "../components/Desktop"
import Mobile from "../components/Mobile"
import { Helmet } from "react-helmet-async";


const Main = () => {
  return (
    <div>
      <Helmet>
        <meta name="title" content="미리웰" />
          <meta
            name="description"
            content="아파서 걱정말고 아프기 전에 미리미리 관리하세요"
          />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://miriwell.com/" />
          <meta property="og:title" content="미리웰" />
          <meta
            property="og:description"
            content="아파서 걱정말고 아프기 전에 미리미리 관리하세요"
          />
          <meta
            property="og:image"
            content="https://i.ibb.co/nDp9Y9g/miriwell-preview.png"
          />
      </Helmet>
      <BrowserView>
        <Desktop />
      </BrowserView>
      <MobileOnlyView>
        <Mobile />
      </MobileOnlyView> 
    </div>
  )
}

export default Main;