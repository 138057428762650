import { BrowserView } from "react-device-detect";
import { useMatch, useNavigate } from "react-router-dom";
import styled from "styled-components";

const Header = () => {
  const navigate = useNavigate();
  const mainMatch = useMatch("/");
  const inquiryMatch = useMatch("/inquiry");

  return (
    <BrowserView style={{ overflowX: "hidden" }}>
      <Container>
        <HeaderBox>
          <Logo
            alt="로고"
            src="/image/iconLogoGreen.svg"
            onClick={() => navigate("/")}
          />
          <Taps>
            <Tap onClick={() => navigate("/")} isActive={mainMatch !== null}>
              서비스
            </Tap>
            <Tap
              onClick={() => navigate("/inquiry")}
              isActive={inquiryMatch !== null}
            >
              제휴 문의
            </Tap>
          </Taps>
        </HeaderBox>
      </Container>
    </BrowserView>
  );
};

const Container = styled.header`
  width: 100vw;
  height: 70px;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Logo = styled.img`
  width: 150px;
  cursor: pointer;
`;

const HeaderBox = styled.div`
  width: 850px;
  display: flex;
  justify-content: space-between;
`;

const Taps = styled.div`
  display: flex;
`;

const Tap = styled.div`
  height: 50px;
  width: 80px;
  margin-left: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-family: "Pretendard-Regular";
  font-weight: 500;
  font-size: 18px;
  color: ${(props) => (props.isActive ? "#5FCE80" : "black")};
`;

export default Header;
