import { Helmet } from "react-helmet-async";
import "./App.css";
import Router from "./shared/Router"

function App() {
  return (
    <>
    <Helmet>
      <title>미리웰</title>
    </Helmet>
      <Router />
    </>
  );
}

export default App;
