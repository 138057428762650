import styled from "styled-components";

const DesktopInquiry = () => {
  return (
    <Container>
      <MainContent>
        <div style={{ marginRight: "30px" }}>
          <h1>
            전국의 모든 기업과 건강 검진 센터를 <span>연결</span>해주는
          </h1>
          <img alt="비즈 로고" src="/image/iconLogoBiz.svg" />
          <h1 style={{ marginTop: "100px", fontSize: "18px" }}>
            건강 검진과 관련된 모든 것을 미리웰 비즈에서 <span>One-Stop</span>
            으로!
            <br />
          </h1>
          <Process>
            <ProcessStep>
              <div>
                <img
                  alt="검진 센터 검색"
                  src="/image/iconSearch.svg"
                  style={{ width: "43px" }}
                />
              </div>
              <div>검진 센터 검색</div>
            </ProcessStep>

            <img alt="다음" src="/image/iconNext.svg" className="next" />

            <ProcessStep>
              <div>
                <img
                  alt="건강 검진 예약"
                  src="/image/iconReserve.svg"
                  style={{ width: "50px" }}
                />
              </div>
              <div>건강 검진 예약</div>
            </ProcessStep>

            <img alt="다음" src="/image/iconNext.svg" className="next" />

            <ProcessStep>
              <div>
                <img
                  alt="미수검자 현황 관리"
                  src="/image/iconManage.svg"
                  style={{ width: "44px", marginTop: "2px" }}
                />
              </div>
              <div>
                미수검자
                <br />
                현황 관리
              </div>
            </ProcessStep>

            <img alt="다음" src="/image/iconNext.svg" className="next" />

            <ProcessStep style={{ marginLeft: "-5px" }}>
              <div>
                <img
                  alt="검진 레포트"
                  src="/image/iconReport.svg"
                  style={{ width: "47px", marginBottom: "3px" }}
                />
              </div>
              <div>
                검진 결과
                <br />
                분석 레포트 제공
              </div>
            </ProcessStep>
          </Process>
        </div>
        <MainImg src="/image/inquiryMain.svg" />
      </MainContent>
    </Container>
  );
};

const Container = styled.div`
  width: 100vw;
  height: 470px;
  display: flex;
  justify-content: center;
  background-color: #f5f5f5;
  // background: linear-gradient(to bottom, #28c985 90%, transparent);
  opacity: 0.82;
  padding-top: 3%;
  font-family: "NanumSquareRound";
  overflow-x: hidden;
`;

const MainContent = styled.div`
  display: flex;
  //min-width: 850px;
  width: 850px;
  justify-content: space-between;

  h1 {
    width: 510px;
    font-weight: 600;
    font-size: 25px;
    span {
      color: #a996f7;
    }
  }
  img {
    width: 240px;
    margin-top: 10px;
  }
`;

const MainImg = styled.img`
  margin-top: -5%;
  min-width: 400px;
`;

const Process = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 30px;

  .next {
    width: 12px;
    margin: -110px 7px 0px 7px;
  }
`;
const ProcessStep = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 200px;

  div:first-child {
    width: 90px;
    height: 90px;
    background: white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;

    img {
      margin: 0;
    }
  }
  div:nth-child(2) {
    font-size: 15px;
    text-align: center;
    line-height: 1.4;
  }
`;
export default DesktopInquiry;
