import Footer from "../components/Footer";
import Header from "../components/Header";
import Inquiry from "../pages/Inquiry";
import Main from "../pages/Main";
const { BrowserRouter, Routes, Route } = require("react-router-dom");

const Router = () => {
  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route path="/" element={<Main />}></Route>
        <Route path="/inquiry" element={<Inquiry />}></Route>
      </Routes>
      <Footer />
    </BrowserRouter>
  );
};

export default Router;