import { useState } from "react";
import styled from "styled-components";

const InquirySection = () => {
  const [type, setType] = useState("");
  const [corName, setCorName] = useState("");
  const [name, setName] = useState("");
  const [region, setRegion] = useState("");
  const [contact, setContact] = useState(0);
  const [email, setEmail] = useState("");
  const [isEmail, setIsEmail] = useState(false);
  const [service, setService] = useState([]);
  const [etc, setEtc] = useState(false);
  const [etcMsg, setEtcMsg] = useState("");

  const submitMsg = {
    type,
    corName,
    name,
    region,
    contact,
    service,
    // email,
    // etcMsg,
  };

  const checkboxHandler = (e) => {
    if (!e.target.checked) {
      const filter = service.filter((s) => s !== e.target.name);
      return setService([...filter]);
    } else {
      return setService([...service, e.target.name]);
    }
  };

  const onChangeEmail = (e) => {
    const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (regex.test(e.target.value)) {
      setEmail(e.target.value);
      setIsEmail(true);
      // console.log("맞음");
    } else {
      setEmail(e.target.value);
      setIsEmail(false);
      // console.log("틀림");
    }
  };


async function postData(url = '', data = {}) {
  const response = await fetch(url, {
    method: 'POST', // *GET, POST, PUT, DELETE 등
    mode: 'cors', // no-cors, *cors, same-origin
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    headers: {
      'Content-Type': 'application/json',
      // 'Content-Type': 'application/x-www-form-urlencoded',
      'Access-Control-Allow-Origin': 'https://api.miriwell.com/extern/business_contect',
    },
   body: JSON.stringify(data), // body의 데이터 유형은 반드시 "Content-Type" 헤더와 일치해야 함
  });
  return response.json(); // JSON 응답을 네이티브 JavaScript 객체로 파싱
};

  const validation = (submit) => {
    if (email.length !== 0) submit.email = email;
    if (etc) submit.etcMsg = etcMsg;

    // console.log("검사 전", submitMsg);

    if (submit.type.length === 0)
      return alert("유형(필수 입력 항목)을 선택해주세요.");
    if (submit.corName.length === 0)
      return alert("회사명(필수 입력 항목)을 입력해주세요.");
    if (submit.name.length === 0)
      return alert("신청자명(필수 입력 항목)을 입력해주세요.");
    if (submit.name.length === 1)
      return alert("올바른 신청자명을 입력해주세요.");
    if (submit.region.length === 0)
      return alert("지역(필수 입력 항목)을 입력해주세요.");
    if (submit.contact === 0)
      return alert("연락처(필수 입력 항목)를 입력해주세요.");
    if (email.length !== 0 && !isEmail)
      return alert("올바른 이메일을 입력해주세요");
    if (submit.contact.length <= 6)
      return alert("올바른 연락처를 입력해주세요.");
    if (submit.service.length === 0)
      return alert("관심 서비스(필수 입력 항목)를 선택해주세요.");
    if (etc && etcMsg.length === 0)
      return alert("기타 관심 서비스(필수 입력 항목)를 입력해주세요");

    // console.log("통과 시", submitMsg);


    postData('https://api.miriwell.com/extern/business_contact', submitMsg)
    .then((data) => {
  // console.log(data);
      alert("문의 접수가 완료되었습니다. 빠른 시일 내로 안내해드리겠습니다.");
      window.location.href = "/inquiry";
    }).catch((error)=> {
      alert("시스템 오류입니다. 문의사항은 하단의 이메일을 통해 제출하실 수 있습니다.")
    });

  };

  return (
    <Container>
      <InquiryBox>
        <Intro>
          <img alt="제휴 문의" src="/image/iconHandshake.svg" />
          <h2>제휴 문의</h2>
          <p>
            미리웰 비즈에 대해 궁금한 점이 있으신가요?
            <br />더 자세한 안내를 위해 연락처를 남겨주세요.
          </p>
          <hr />
        </Intro>

        <form
          onSubmit={(e) => {
            e.preventDefault();
            validation(submitMsg);
          }}
        >
          <Question style={{ alignItems: "start" }}>
            <div className="asking">
              유형 <span>*</span>
            </div>

            <CheckBox>
              <p style={{ marginBottom: "25px" }}>
                제휴 문의 유형을 선택해주세요.{" "}
              </p>

              <div>
                <input
                  type="checkbox"
                  id="input1"
                  name="기업 건강 검진 도입 문의"
                  checked={type === "기업 건강 검진 도입 문의" ? true : false}
                  onChange={(e) => {
                    setType(e.target.name);
                  }}
                />
                <label htmlFor="input1">기업 건강 검진 도입 문의</label>
              </div>
              <div>
                <input
                  type="checkbox"
                  id="input2"
                  name="건강 검진 센터 입점 문의"
                  checked={type === "건강 검진 센터 입점 문의" ? true : false}
                  onChange={(e) => {
                    setType(e.target.name);
                  }}
                />
                <label htmlFor="input2">건강 검진 센터 입점 문의</label>
              </div>
            </CheckBox>
          </Question>

          <Question>
            <div className="asking">
              회사명 <span>*</span>
            </div>
            <Input
              placeholder="회사명을 입력해주세요."
              onChange={(e) => {
                setCorName(e.target.value);
              }}
            />
          </Question>

          <Question>
            <div className="asking">
              신청자명 <span>*</span>
            </div>
            <Input
              placeholder="성함을 입력해주세요."
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
          </Question>

          <Question>
            <div className="asking">
              지역 <span>*</span>
            </div>

            <Select
              onChange={(e) => {
                setRegion(e.target.value);
              }}
            >
              <option value="">지역을 선택해주세요.</option>
              <option value="서울특별시">서울특별시</option>
              <option value="부산광역시">부산광역시</option>
              <option value="대구광역시">대구광역시</option>
              <option value="인천광역시">인천광역시</option>
              <option value="광주광역시">광주광역시</option>
              <option value="대전광역시">대전광역시</option>
              <option value="울산광역시">울산광역시</option>
              <option value="세종특별자치시">세종특별자치시</option>
              <option value="경기도">경기도</option>
              <option value="강원도">강원도</option>
              <option value="충청도">충청도</option>
              <option value="전라도">전라도</option>
              <option value="경상도">경상도</option>
              <option value="제주도">제주도</option>
            </Select>
          </Question>

          <Question>
            <div className="asking">
              연락처 <span>*</span>
            </div>
            <Input
              type="number"
              onKeyDown={(e) =>
                ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
              }
              placeholder="- 없이 입력해주세요."
              onChange={(e) => {
                setContact(e.target.value);
              }}
            />
          </Question>

          <Question style={{ alignItems: "start" }}>
            <div className="asking" style={{ marginTop: "8px" }}>
              이메일
            </div>
            <div>
              <Input
                placeholder="abc@abc.com"
                onChange={(e) => {
                  onChangeEmail(e);
                }}
              />
              {isEmail ? (
                <Email isEmail={true}>올바른 이메일입니다.</Email>
              ) : email.length === 0 ? null : (
                <Email isEmail={false}>올바른 이메일 양식이 아닙니다.</Email>
              )}
            </div>
          </Question>

          <Question style={{ alignItems: "start" }}>
            <div className="asking">
              관심 서비스 <span>*</span>
            </div>

            <CheckBox>
              <p>관심이 가는 서비스를 선택해주세요. </p>
              <p>* 중복 선택 가능</p>

              <div>
                <input
                  type="checkbox"
                  id="input3"
                  name="검진 센터 컨택"
                  onChange={(e) => {
                    checkboxHandler(e);
                  }}
                />
                <label htmlFor="input3">검진 센터 컨택</label>
              </div>
              <div>
                <input
                  type="checkbox"
                  id="input4"
                  name="기업 건강검진(임직원 건강검진)"
                  onChange={(e) => {
                    checkboxHandler(e);
                  }}
                />
                <label htmlFor="input4">기업 건강검진 (임직원 건강검진)</label>
              </div>
              <div>
                <input
                  type="checkbox"
                  id="input5"
                  name="검진 센터 예약"
                  onChange={(e) => {
                    checkboxHandler(e);
                  }}
                />
                <label htmlFor="input5">검진 센터 예약</label>
              </div>
              <div>
                <input
                  type="checkbox"
                  id="input6"
                  name="미수검자 추적 · 관리"
                  onChange={(e) => {
                    checkboxHandler(e);
                  }}
                />
                <label htmlFor="input6">미수검자 추적 · 관리</label>
              </div>
              <div>
                <input
                  type="checkbox"
                  id="input7"
                  name="기타"
                  onChange={(e) => {
                    checkboxHandler(e);
                    setEtc(e.target.checked);
                  }}
                />
                <label htmlFor="input7">기타</label>
              </div>
              <Input
                placeholder="원하시는 서비스를 입력해주세요."
                isActive={etc}
                onChange={(e) => {
                  setEtcMsg(e.target.value);
                }}
              />
            </CheckBox>
          </Question>
          <hr style={{ marginTop: "0" }} />
          <Submit>
            <div>
              <div style={{ marginBottom: "10px" }}>
                <span>*</span> 는 필수 입력 항목입니다.
              </div>
              <div>
                * 개인정보는 상담을 목적으로만 사용되며, 일정기간 이후 폐기 처리
                합니다.
              </div>
            </div>
            <button>문의하기</button>
          </Submit>
        </form>
      </InquiryBox>
    </Container>
  );
};

const Container = styled.div`
  width: 100vw;
  display: flex;
  justify-content: center;
  font-family: "Pretendard-Regular";
  margin: 40px 0 1600px 0;
`;

const InquiryBox = styled.div`
  height: 100px;
  width: 850px;

  form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  hr {
    width: 850px;
    margin: 60px 0 60px 0;
  }
`;

const Intro = styled.div`
  text-align: center;

  p {
    line-height: 1.5;
    margin: 0;
  }
  img {
    width: 200px;
    margin-bottom: 7px;
  }
`;

const Question = styled.div`
  width: 400px;
  /* background: aqua; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  margin-bottom: 60px;

  .asking {
    width: 100px;
    /* background: red; */

    span {
      color: #5fce80;
    }
  }
`;

const CheckBox = styled.div`
  width: 230px;
  display: flex;
  flex-direction: column;
  align-items: start;
  font-size: 15px;

  p:first-child {
    margin: 2px 0;
  }
  p:nth-child(2) {
    font-size: 12px;
    color: grey;
    margin: 10px 0px 25px 0px;
    color: #5fce80;
  }
  div {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  input {
    width: 18px;
    height: 18px;
    margin: 0;
    accent-color: grey;
    cursor: pointer;
  }
  label {
    margin-left: 15px;
    cursor: pointer;
  }
`;

const Input = styled.input`
  min-width: 230px;
  min-height: 37px;
  box-sizing: border-box;
  border: none;
  background: #f5f5f5;
  border-radius: 10px;
  padding-left: 12px;
  font-size: 15px;
  font-family: "Pretendard-Regular";

  &:focus {
    outline: 1px solid #5fce80;
    background: white;
  }

  display: ${(props) =>
    props.isActive ? "block" : props.isActive === undefined ? "block" : "none"};

  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const Email = styled.div`
  margin-top: 10px;
  font-size: 15px;
  color: ${(props) => (props.isEmail ? "#5fce80" : "red")};
`;

const Select = styled.select`
  width: 230px;
  min-height: 37px;
  background: #f5f5f5;
  border: none;
  border-radius: 10px;
  padding-left: 7px;
  cursor: pointer;
  font-size: 15px;
  font-family: "Pretendard-Regular";

  &:focus {
    outline: 1px solid #5fce80;
    background: white;
  }
`;

const Submit = styled.div`
  font-size: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;

  span {
    color: #5fce80;
  }
  button {
    width: 180px;
    height: 50px;
    border: none;
    border-radius: 15px;
    background: #5fce80;
    color: white;
    font-size: 16px;
    font-weight: bold;
    margin: 60px 0px 60px 0px;
    cursor: pointer;
    font-family: "Pretendard-Regular";
  }
`;

export default InquirySection;
