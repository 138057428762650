import styled from "styled-components";

const Mobile = () => {
  return (
    <Container>
      <MainContent>
        <Logo src="/image/logo.svg" />
        <h1>
          아파서 걱정말고 <br />
          아프기 전에 <br />
          미리미리 관리하세요
        </h1>

        <MainImg src="/image/main.svg" />
        <DownLoad>
          <p>앱스토어 다운로드</p>
          <div>
            <a href="https://play.google.com/store/apps/details?id=com.rexsoft.miriwell&hl=ko">
              <StoreBox>
                <img src="/image/iconGoogle.svg" />
              </StoreBox>
            </a>
            <a href="https://apps.apple.com/kr/app/%EB%AF%B8%EB%A6%AC%EC%9B%B0/id1658463338">
              <StoreBox>
                <img src="/image/iconAppStore.svg" />
              </StoreBox>
            </a>
          </div>
        </DownLoad>
      </MainContent>
      {/* <Intro>
        <h1>미리미리 건강성적 관리하세요!</h1>
        <h3>지금부터 미리웰과 함께하면 앞으로 더 건강한 삶을 살 수 있어요</h3>
        <img src="/image/score.svg" />
      </Intro> */}
    </Container>
  );
};

const Container = styled.div`
  width: 100vw;
  // height: 100vh;
  display: flex;
  // flex-direction: column;
  background-color: #28c985;
  opacity: 0.82;
  padding: 5% 0 0 5%;
`;

const Logo = styled.img`
  width: 150px;
  margin-bottom: 40px;
`;

const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  // align-items: center;
  width: 850px;
  background-color: #28c985;

  h1 {
    // width: 430px;
    color: white;
    font-family: "NanumSquareRound";
    font-weight: 800;
    font-size: 30px;
  }
`;

const DownLoad = styled.div`
  display: flex;
  flex-direction: column;

  p {
    font-family: "NanumSquareRound";
    font-weight: 800;
    font-size: 17px;
    color: white;
  }
  div {
    display: flex;
    // flex-direction: column;
  }
`;

const StoreBox = styled.div`
  width: 130px;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 40px;
  background-color: white;
  // margin-right: 15px;
  margin: 0px 15px 50px 0px;
  cursor: pointer;

  img {
    width: 100px;
  }
`;

const MainImg = styled.img`
  width: 350px;
  //margin-top: -5%;
`;

const Intro = styled.div`
  text-align: center;
  padding-bottom: 50px;

  h1 {
    font-size: 20px;
    font-weight: 800;
    color: #28c985;
    margin: 0;
    padding-top: 100px;
  }
  h3 {
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 70px;
  }
  img {
    width: 100px;
  }
`;

export default Mobile;
